import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import Screens from 'theme/screens'
import { useText } from 'texts'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 12px;

  width: 570px;
  height: 184px;

  @media (max-width: ${Screens.sm}) {
    width: 290px;
    height: 115px;
  }
`

const ImageDeskContent = styled.div`
  display: flex;

  @media (max-width: ${Screens.sm}) {
    display: none;
  }
`

const StyledSwiper = styled(Swiper)`
  height: 100%;
  display: none;

  @media (max-width: ${Screens.sm}) {
    display: flex;
  }
`

const CreateCampaignImage = () => {
  const { t } = useText()

  return (
    <ImageContainer>
      <StyledSwiper
        slidesPerView={1}
        spaceBetween={50}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <StaticImage
            src="ads.png"
            alt={t('about.steps.createCampaign.alt')}
            placeholder="dominantColor"
            layout="constrained"
            objectFit='contain'
            width={400}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="budget.png"
            alt={t('about.steps.createCampaign.alt')}
            placeholder="dominantColor"
            layout="constrained"
            objectFit='contain'
            width={400}
          />
        </SwiperSlide>
      </StyledSwiper>
      
      <ImageDeskContent>
        <StaticImage
          src="ads.png"
          alt={t('about.steps.createCampaign.alt')}
          placeholder="dominantColor"
          layout="constrained"
          objectFit='contain'
          quality={100}
        />
        <StaticImage
          src="budget.png"
          alt={t('about.steps.createCampaign.alt')}
          placeholder="dominantColor"
          layout="constrained"
          objectFit='contain'
          quality={100}
        />
      </ImageDeskContent>
    </ImageContainer>
  )
}

export default CreateCampaignImage
