import React from 'react'
import styled from 'styled-components'

import Hero from './Hero'
import Step from './Step'
import CreateCampaignImage from './CreateCampaignImage'
import ResultsImage from './ResultsImage'

const steps = [
  {
    tKey: 'products'
  },
  {
    tKey: 'createCampaign',
    asset: <CreateCampaignImage />
  },
  {
    tKey: 'results',
    asset: <ResultsImage />
  },
  {
    tKey: 'support'
  }
]

const StyledAbout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const About = () => {
  return (
    <StyledAbout>
      <Hero />
      {steps.map(({ tKey, asset }, index) => (
        <Step
          key={tKey}
          tKey={tKey}
          asset={asset}
          number={index + 1}
          highlight={index % 2 === 0}
        />
      ))}
    </StyledAbout>
  )
}

export default About
