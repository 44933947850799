import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import Screens from 'theme/screens'
import { useText } from 'texts'

const ImageContainer = styled.div`
  width: 462px;
  height: 138px;

  @media (max-width: ${Screens.sm}) {
    width: 293px;
    height: 115px;
  }
`

const ResultsImage = () => {
  const { t } = useText()

  return (
    <ImageContainer>
      <StaticImage
        src="table.png"
        alt={t('about.steps.results.alt')}
        placeholder="dominantColor"
        layout="constrained"
        quality={100}
      />
    </ImageContainer>
  )
}

export default ResultsImage
