import React from 'react'
import styled from 'styled-components'

import Colors from 'theme/colors'
import Screens from 'theme/screens'
import { useText } from 'texts'

const StyledStep = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ highlight }) =>
    highlight ? Colors.GRAY_100 : Colors.NEUTRAL_100};
  padding: 32px 0;
`

const Container = styled.div`
  display: ${({ hasAssets }) => (hasAssets ? 'grid' : 'flex')};
  grid-template-columns: 50% 50%;
  grid-column-gap: 16px;

  @media (max-width: ${Screens.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const TextContainer = styled.div`
  align-self: center;

  @media (max-width: ${Screens.sm}) {
    text-align: center;
  }
`

const AssetContainer = styled.div`
  margin-right: 24px;

  @media (max-width: ${Screens.md}) {
    margin-top: 16px;
    margin-right: 0;
  }
`

const Step = ({ tKey, asset, number, highlight }) => {
  const { t } = useText()

  return (
    <StyledStep highlight={highlight}>
      <Container hasAsset={!!asset} className="container full">
        <TextContainer>
          <h2 className="text-primary-100">
            {`${number.toLocaleString('pt-br', { minimumIntegerDigits: 2 })}. `}
            {t(`about.steps.${tKey}.title`)}
          </h2>
          <span>{t(`about.steps.${tKey}.description`)}</span>
        </TextContainer>
        {!!asset && <AssetContainer>{asset}</AssetContainer>}
      </Container>
    </StyledStep>
  )
}

export default Step
