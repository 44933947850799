import React from 'react'
import styled from 'styled-components'

import Screens from 'theme/screens'
import Illustration from 'svgs/about.svg'
import { useText } from 'texts'

const StyledHero = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;

  @media (max-width: ${Screens.md}) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: ${Screens.sm}) {
    display: flex;
    flex-direction: column;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-right: 20px;

  @media (max-width: ${Screens.sm}) {
    text-align: center;
    padding-right: 0;
  }
`

const IllustrationContainer = styled.div`
  display: flex;
  margin-bottom: -48px;
  z-index: 1;

  @media (max-width: ${Screens.md}) {
    max-width: 365px;
    max-height: 256px;
    margin-bottom: 0px;
  }

  @media (max-width: ${Screens.sm}) {
    align-self: center;
    margin-bottom: -24px;
  }
`

const Hero = () => {
  const { t } = useText()

  return (
    <StyledHero>
      <Container className="container full">
        <TextContainer>
          <h1 className="text-primary-100">{t('about.hero.title')}</h1>
          <span>{t('about.hero.description')}</span>
        </TextContainer>

        <IllustrationContainer>
          <Illustration width="100%" height="100%" />
        </IllustrationContainer>
      </Container>
    </StyledHero>
  )
}

export default Hero
